import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useHistory } from "react-router-dom";

function Logout({ apiKey }: { apiKey: string }) {
  const history = useHistory();
  const isDevelopment = window.location.hostname === "localhost";
  const [isLoggedOut, setIsLoggedOut] = React.useState(false);

  useEffect(() => {
    firebase.initializeApp({
      projectId: isDevelopment ? "demo-agileday" : "agileday-gateway",
      apiKey
    });
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsLoggedOut(true);
      })
      .catch((_error) => {
        // ignore errors
      });
  }, []);

  return (
    <div>
      <h1>You’ve been signed out, see you soon again!</h1>
      {isLoggedOut ? (
        <button
          onClick={() => {
            history.push("/");
          }}
        >
          Sign back in?
        </button>
      ) : (
        <h5>Signing you out...</h5>
      )}
    </div>
  );
}

export default Logout;
