import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase/compat/app";
import { FirebaseAuth } from "@firebase/auth-types";
import * as firebaseui from "firebaseui";
import * as ciap from "gcip-iap";
import "firebaseui/dist/firebaseui.css";
import AgiledayAuthHandler from "./AgiledayAuthHandler";
import { PrivacyPolicyLink } from "./PrivacyPolicyLink";

export type TenantConfig = {
  error?: unknown;
  signInOptions: firebaseui.auth.Config["signInOptions"];
  displayName?: string;
};

const isDevelopment =
  window.location.hostname === "localhost" ||
  window.location.hostname === "app.xn--fpaa.fi";
const COMMON_CONFIG: Omit<firebaseui.auth.CIAPHandlerConfig, "tenants"> = {
  authDomain: window.location.hostname,
  displayMode: "identifierFirst",
  callbacks: {
    beforeSignInSuccess: async (user: firebase.User) => {
      const idToken = await user.getIdToken(true);
      const csrfToken = document.querySelector<HTMLMetaElement>(
        'meta[name="csrf-token"]'
      ).content;

      // Validate the user's JWT token for the tenant access
      const body = new URLSearchParams();
      body.append("token", idToken);
      body.append("csrfToken", csrfToken);
      body.append("tenantId", user.tenantId || "");

      const response = await fetch("/auth", { method: "POST", body });
      if (!response.ok) {
        throw new Error("Failed to validate the user's token");
      }

      // Locally, we don't want to pass handling for IAP,
      // but redirect to the frontend immediately after succesful auth
      if (isDevelopment) {
        // Locally user directly to the frontend
        const uri = new URLSearchParams(window.location.search).get(
          "redirect_uri"
        );
        window.location.href = uri || "/";
        // Wait for the redirect
        return new Promise((_resolve, reject) => {
          setTimeout(() => {
            reject(new Error("Redirect timeout"));
          }, 45000);
        });
      }

      return user;
    }
  }
};

// For more customized login flow, read these:
// - https://github.com/GoogleCloudPlatform/iap-gcip-web-toolkit/tree/master/sample/authui-react
// - https://cloud.google.com/iap/docs/create-custom-auth-ui
function TenantLogin({ apiKey }: { apiKey: string }) {
  const ciapRef = useRef<ciap.Authentication>(null);
  const params = new URLSearchParams(window.location.search);
  const tenantId = params.get("tid");
  const mode = params.get("mode");
  const [tenant, setTenant] = useState<TenantConfig | null>(null);

  useEffect(() => {
    if (mode === "signout" && !tenantId) {
      window.location.href = "/logout";
    }
  }, [mode]);

  useEffect(() => {
    const fetchConfig = async () => {
      const response = await fetch("/get-providers?tid=" + tenantId);
      const tenantConfig = (await response.json()) as TenantConfig;

      if (tenantConfig.error || tenantConfig.signInOptions?.length === 0) {
        console.error("Error fetching tenant config", tenantConfig);
        return;
      }

      setTenant(tenantConfig);
    };

    if (tenantId) {
      fetchConfig().catch((_error) => {
        // ignore errors
      });
    }
  }, [tenantId]);

  useEffect(() => {
    if (tenant === null) {
      return;
    }

    const handlerConfig = {
      [apiKey]: {
        ...COMMON_CONFIG,
        tenants: {
          [tenantId]: tenant
        }
      }
    };

    try {
      const handler = new AgiledayAuthHandler(
        "#firebaseui-container",
        handlerConfig
      );

      if (ciapRef.current === null) {
        const auth = handler.getAuth(apiKey, tenantId) as FirebaseAuth;

        // Locally connect to the Firebase Auth emulator
        if (isDevelopment) {
          auth.useEmulator("https://app.xn--fpaa.fi");
        }

        ciapRef.current = new ciap.Authentication(handler);
        ciapRef.current.start();
      }
    } catch (e) {
      console.log("Unable to initialize Firebase UI", e);
    }
  }, [tenant, tenantId]);

  return (
    <div>
      <h1>Good to see you</h1>
      {!tenant && <div>Loading...</div>}
      {tenant && (
        <h5 className="tenant-heading">
          Sign in to <span>{tenant.displayName}</span>
        </h5>
      )}
      <div id="firebaseui-container"></div>
      <p className="privacy-policy-note">
        By signing in, you agree to our <PrivacyPolicyLink />
      </p>
    </div>
  );
}

export default TenantLogin;
